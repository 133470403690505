// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.circle {
  border-radius: 50%;
  background-color: rgba(237, 69, 69, 1);
  width: 50px;
  height: 50px;
  position: absolute;
  opacity: 0;
  animation: scaleIn 2s infinite cubic-bezier(0.36, 0.11, 0.89, 0.32);
}

@keyframes scaleIn {
  from {
    transform: scale(0.5, 0.5);
    opacity: 0.5;
  }
  to {
    transform: scale(1.5, 1.5);
    opacity: 0;
  }
}

.item {
  z-index: 100;
  padding: 5px;
}

.item img {
  width: 30px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/CareerCoach/VideoInterview/style.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,sCAAsC;EACtC,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,UAAU;EACV,mEAAmE;AACrE;;AAEA;EACE;IACE,0BAA0B;IAC1B,YAAY;EACd;EACA;IACE,0BAA0B;IAC1B,UAAU;EACZ;AACF;;AAEA;EACE,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,WAAW;AACb","sourcesContent":[".circle {\n  border-radius: 50%;\n  background-color: rgba(237, 69, 69, 1);\n  width: 50px;\n  height: 50px;\n  position: absolute;\n  opacity: 0;\n  animation: scaleIn 2s infinite cubic-bezier(0.36, 0.11, 0.89, 0.32);\n}\n\n@keyframes scaleIn {\n  from {\n    transform: scale(0.5, 0.5);\n    opacity: 0.5;\n  }\n  to {\n    transform: scale(1.5, 1.5);\n    opacity: 0;\n  }\n}\n\n.item {\n  z-index: 100;\n  padding: 5px;\n}\n\n.item img {\n  width: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
