import React, { useEffect } from 'react';

const ExitHandler = () => {
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      // Custom message for modern browsers (won't be displayed in some)
      const message = 'Are you sure you want to leave? Your changes may not be saved.';
      event.preventDefault(); // This is necessary for Chrome
      event.returnValue = message; // Standard way to set the message
      return message; // Some browsers require this to show the message
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

};

export default ExitHandler;
