import React, { useContext, useEffect } from "react";
import { getLocalStorage, setLocalStorage } from "../../../helpers/common";
import { CareerCoachInterviewContext } from "../VideoInterview/CareerCoachInterviewContext";
import { headers, usefetch } from "../../../helpers/fetch";
import { useLocation, useNavigate } from "react-router-dom";
export default function CallBack() {
  const {
    setPaymentRes,
    setPaymenttSuccessToggle,
    setOrderAmount,
    setOrderId,
  } = useContext(CareerCoachInterviewContext);
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const paramsObject = {};
    queryParams.forEach((value, key) => {
      paramsObject[key] = value;
    });

    const OrderId = getLocalStorage("OrderId");
    const OrderPayment = getLocalStorage("OrderAmount");
    if (Object.keys(paramsObject).length > 2) {
      usefetch("packages/varifyCallBack", "post", headers(), {
        callback: paramsObject,
        OrderId: OrderId,
        OrderPayment: OrderPayment,
      }).then(function (resp) {
        if (resp?.code == "11" && resp?.data?.transaction_id != undefined) {
          setPaymentRes({
            code: "11",
            message: "You have successfully made the payment.",
            heading: "Congratulations!",
          });
        } else if(resp?.data?.errCode === '911') {
          setPaymentRes({
            code: resp?.data?.errCode,
            message: "You have canceled the payment",
            heading: "Transaction not completed",
          });
        }else {
          setPaymentRes({
            code: resp?.data?.errCode,
            message: resp?.data?.message,
            heading: "Transaction not completed",
          });
        }
        setPaymenttSuccessToggle(true);
        setOrderAmount(0);
        setOrderId(null);
        setLocalStorage("OrderId", "");
        setLocalStorage("OrderAmount", "");

        // Redirect to dashboard
        navigate("/career-coach/dashboard");
      });
    }
  }, [location.search]);

  return null;
}
