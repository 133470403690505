export const secondsToHhourMminuteSseconds = (d) => {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);
  
    var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? ` minute ${ s > 0 ? ',' : ''}` : ` minutes ${ s > 0 ? ',' : ''}`) : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    return hDisplay + mDisplay + sDisplay;
}

export const zeroFill = (num) => (num < 10 ? `0${num}` : num);

export const secondsToHms = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    return `${zeroFill(hours)}:${zeroFill(minutes)}:${zeroFill(
      remainingSeconds,
    )}`
}

export const convertMinutesToSeconds = (minutes) => {
    return minutes * 60;
}

export const convertSecondsToMinutes = (seconds) => {
    return Math.floor(seconds / 60);
}
export const secondsToHmsString = (d) => {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? ` minute ${s > 0 ? ',' : ''}` : ` minutes ${s > 0 ? ',' : ''}`) : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    return hDisplay + mDisplay + sDisplay;
}