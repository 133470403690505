export { ReactComponent as XIcon } from '../images/x-icon.svg';
export { ReactComponent as NoData } from '../images/no-data.svg';
export { ReactComponent as PDFIcon } from '../images/pdf-icon.svg';
export { ReactComponent as AddIcon } from '../images/add-icon.svg';
export { ReactComponent as EyeIcon } from '../images/eye-icon.svg';
export { ReactComponent as LogoutIcon } from '../images/logout.svg';
export { ReactComponent as Packages } from '../images/packages.svg';
export { ReactComponent as ChatIcon } from '../images/chat-icon.svg';
export { ReactComponent as InfoIcon } from '../images/info-icon.svg';
export { ReactComponent as MyCvIcon } from '../images/mycv-icon.svg';
export { ReactComponent as CartIcon } from '../images/cart-icon.svg';
export { ReactComponent as InputIcon } from '../images/send-icon.svg';
export { ReactComponent as RozeeLogo } from '../images/rozee-logo.svg';
export { ReactComponent as SmileIcon } from '../images/smile-face.svg';
export { ReactComponent as ShareIcon } from '../images/share-icon.svg';
export { ReactComponent as TestTaken } from '../images/test-taken.svg';
export { ReactComponent as PoweredBy } from '../images/powered-by.svg';
export { ReactComponent as MCQTestIcon } from '../images/MCQ-icon.svg';
export { ReactComponent as GoogleIcon } from '../images/google-icon.svg';
export { ReactComponent as DeleteIcon } from '../images/delete-icon.svg';
export { ReactComponent as AttachIcon } from '../images/attach-icon.svg';
export { ReactComponent as RocketIcon } from '../images/rocket-icon.svg';
export { ReactComponent as UploadIcon } from '../images/upload-icon.svg';
export { ReactComponent as RecentIcon } from '../images/recent-icon.svg';
export { ReactComponent as CopyLinkIcon } from '../images/copy-link.svg';
export { ReactComponent as AudioPause } from '../images/audio-paused.svg';
export { ReactComponent as MatchedIcon } from '../images/dot-matched.svg';
export { ReactComponent as SearchIcon } from '../images/search__icon.svg';
export { ReactComponent as NoJobFound } from '../images/no-job-found.svg';
export { ReactComponent as EditCVIcon } from '../images/edit-cv-icon.svg';
export { ReactComponent as RefreshIcon } from '../images/refresh-icon.svg';
export { ReactComponent as SigninIcon } from '../images/sign-in-arrow.svg';
export { ReactComponent as ReRecording } from '../images/re-recording.svg';
export { ReactComponent as AudioPlaying } from '../images/audio-playing.svg';
export { ReactComponent as ViewCVNew } from '../images/view-cv-new-icon.svg';
export { ReactComponent as UpgradeCrown } from '../images/upgrade-crown.svg';
export { ReactComponent as DownloadIcon } from '../images/download-icon.svg';
export { ReactComponent as CrownPremium } from '../images/crown-premium.svg';
export { ReactComponent as WhatsappIcon } from '../images/whatsapp-icon.svg';
export { ReactComponent as CheckboxIcon } from '../images/checkbox-icon.svg';
export { ReactComponent as JobListingIcon } from '../images/job-listing.svg';
export { ReactComponent as FacebookIcon } from '../images/facebook-icon.svg';
export { ReactComponent as LinkedinIcon } from '../images/linkedin-icon.svg';
export { ReactComponent as NoDataCartIcon } from '../images/no-cart-data.svg';
export { ReactComponent as DeleteIconCV } from '../images/delete-icon-cv.svg';
export { ReactComponent as MagicWrite } from '../images/magic-write-icon.svg';
export { ReactComponent as CartIconDark } from '../images/cart-icon-dark.svg';
export { ReactComponent as CVViewIcon } from '../images/cv-view-icon-emp.svg';
export { ReactComponent as DarkInputIcon } from '../images/darkInputIcon.svg';
export { ReactComponent as CreateCVIcon } from '../images/create-cv-icon.svg';
export { ReactComponent as PlusIconFilled } from '../images/AddFilledIcon.svg';
export { ReactComponent as InterviewGuru } from '../images/interview-guru.svg';
export { ReactComponent as VideoTestIcon } from '../images/videotest-icon.svg';
export { ReactComponent as DashboardIcon } from '../images/dashboard-line.svg';
export { ReactComponent as StopRecording } from '../images/stop-recording.svg';
export { ReactComponent as LogoutNewIcon } from '../images/logout-new-icon.svg';
export { ReactComponent as ShareFillIcon } from '../images/share-fill-icon.svg';
export { ReactComponent as OTPIconSeeker } from '../images/OTP-icon-seeker.svg';
export { ReactComponent as ComingSoon } from '../images/under-construction.svg';
export { ReactComponent as EditIconTable } from '../images/edit-icon-table.svg';
export { ReactComponent as VectorGPTLeft } from '../images/vector-gpt-left.svg';
export { ReactComponent as TranscriptIcon } from '../images/transcript-icon.svg';
export { ReactComponent as JobPostedTick } from '../images/job-posted-check.svg';
export { ReactComponent as CodingTestIcon } from '../images/codingtest-icon.svg';
export { ReactComponent as DelSkillIcon } from '../images/delete-skill-icon.svg';
export { ReactComponent as StartRecording } from '../images/start-recording.svg';
export { ReactComponent as LockIconAccess } from '../images/lock-icon-access.svg';
export { ReactComponent as videoAudioAccess } from '../images/videoAudio.svg';
export { ReactComponent as FilterNewIcon } from '../images/filter__new__icon.svg';
export { ReactComponent as DownloadCVIcon } from '../images/download-cv-icon.svg';
export { ReactComponent as CVViewedIcon } from '../images/cv-viewed-icon-emp.svg';
export { ReactComponent as UploadCVSeeker } from '../images/upload-cv-seeker.svg';
export { ReactComponent as VectorGPTRight } from '../images/vector-gpt-right.svg';
export { ReactComponent as JobPostedCheck } from '../images/job-posted-check.svg';
export { ReactComponent as ShareEmailIcon } from '../images/share-email-icon.svg';
export { ReactComponent as WhiteLogoNoText } from '../images/WhiteLogoNoText.svg';
export { ReactComponent as PackagesPremium } from '../images/packages-premium.svg';
export { ReactComponent as PlusIconOutlined } from '../images/addoutlinedicon.svg';
export { ReactComponent as CopyLinkIconShare } from '../images/copy-link-icon.svg';
export { ReactComponent as UploadIconButton } from '../images/upload-btn-icon.svg';
export { ReactComponent as LogoWhite } from '../images/rozee-gpt-logo-white.svg';
export { ReactComponent as LogoBlack } from '../images/rozee-gpt-logo-black.svg';
export { ReactComponent as InputIconBlack } from '../images/send-icon-black.svg';
export { ReactComponent as VerifyEmailIcon } from '../images/verify-email-icon.svg';
export { ReactComponent as NewUserSeeker } from '../images/new-user-seeker-img.svg';
export { ReactComponent as UswrDetailsIcon } from '../images/user-details-icon.svg';
export { ReactComponent as CustomizeCVIcon } from '../images/customize-cv-icon.svg';
export { ReactComponent as InputIconWhite } from '../images/input-icon-white.svg';
export { ReactComponent as BestInterviewCC } from '../images/best-interview-cc.svg';
export { ReactComponent as ProfileSettingIcon } from '../images/profile-setting.svg';
export { ReactComponent as DashboardIconMobile } from '../images/dashboard-icon.svg';
export { ReactComponent as DashboardIconNew } from '../images/dashboard-icon-new.svg';
export { ReactComponent as ReloadIconAccess } from '../images/reload-icon-access.svg';
export { ReactComponent as CallTemplateIcon } from '../images/call-template-icon.svg';
export { ReactComponent as RecordingIconNew } from '../images/recording-new-icon.svg';
export { ReactComponent as MinusIconOutlined } from '../images/removeoutlinedicon.svg';
export { ReactComponent as IOSPermissionIcon } from '../images/ios-permission-icon.svg';
export { ReactComponent as EmailTemplateIcon } from '../images/email-template-icon.svg';
export { ReactComponent as EditIconTableBlack } from '../images/EditTableIconBlack.svg';
export { ReactComponent as MicIconAccess } from '../images/mic-access-icon-green.svg';
export { ReactComponent as CheckedBlackIcon } from '../images/checked-black-icon.svg';
export { ReactComponent as InterviewGuruIcon } from '../images/interview-guru-icon.svg';
export { ReactComponent as DashboardIconCC } from '../images/rozeegpt-dashboard-cc.svg';
export { ReactComponent as UploadCvPopupIcon } from '../images/upload-cv-popup-icon.svg';
export { ReactComponent as SortingIconFilter } from '../images/sorting__icon__table.svg';
export { ReactComponent as SuccessTestAttempt } from '../images/success-test-attempt.svg';
export { ReactComponent as ChooseTemplateIcon } from '../images/choose-template-icon.svg';
export { ReactComponent as CreatePasswordIcon } from '../images/create-password-icon.svg';
export { ReactComponent as PlayIconTestResult } from '../images/play-icon-test-result.svg';
export { ReactComponent as WebsiteTemplteIcon } from '../images/website-template-icon.svg';
export { ReactComponent as AIWhiteLogoBlackBg } from '../images/AI-Logo-notext-black.svg';
export { ReactComponent as AIBlackLogoWhiteBg } from '../images/AI-Logo-notext-white.svg';
export { ReactComponent as AccountOverviewIcon } from '../images/account-overview-icon.svg';
export { ReactComponent as CheckboxCheckedIcon } from '../images/checkbox-checked-icon.svg';
export { ReactComponent as EvaluationCriteriaSvg } from '../images/evaluation-criteria.svg';
export { ReactComponent as PersonalInfoSetting } from '../images/personal-info-setting.svg';
export { ReactComponent as LocationTemplateIcon } from '../images/location-template-icon.svg';
export { ReactComponent as ShowHideQuestionIcon } from '../images/show-hide-question-icon.svg';
export { ReactComponent as PlayCircleFilledIcon } from '../images/play-circle-filled-icon.svg';
export { ReactComponent as AccountOverviewIconDark } from '../images/account-overview-dark.svg';
export { ReactComponent as CameraIonAcessBlack } from '../images/camera-icon-access-black.svg';
export { ReactComponent as CameraIonAcessGreen } from '../images/camera-icon-access-green.svg';
export { ReactComponent as InputIconWhiteFilled } from '../images/input-icon-white-filled.svg';
export { ReactComponent as UpgradeBannerStartIcon } from '../images/upgrade-banner-start-icon.svg';
export { ReactComponent as PlayCircleOutlinedIcon } from '../images/play-circle-outlined-icon.svg';
export { ReactComponent as EvaluationCriteriaLoader } from '../images/animation-criteria-loader.svg';
