import { TemplateContext } from './TemplateContext';
import { useTemplate } from './useTemplate';

export const TemplateProvider = ({ children }) => {
  const values = useTemplate();
  return (
    <TemplateContext.Provider value={values}>
      {children}
    </TemplateContext.Provider>
  );
};
