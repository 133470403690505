import React from 'react';

import { Divider, Stack, Typography } from '@mui/material';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';

import { useTemplateContext } from '../../../../../context/TemplateContext/useTemplateContext';
import { DeleteIconCV } from '../../../../../assets/constants';
import EditableTypography from "../../../../../common/EditableTypography";
import {useCvBuilderContext} from "../../../../../context/CvBuilderContext/useCvBuilderContext";

export default function Awards() {
  const {
    formatDate,
    addNewAward,
    awards,
    updateAwardField,
    handleDeleteAward,
  } = useCvBuilderContext();

  return (
    <Stack py={3}>
      <Stack
        py={1}
        spacing={1.2}
        direction='row'
        alignItems='center'
        justifyContent='flex-start'
      >
        <Typography variant='title18Bold' sx={{ fontWeight: '600' }}>
          Awards
        </Typography>

        <Typography
          sx={{
            cursor: 'pointer',
          }}
          onClick={addNewAward}
        >
          <AddCircleOutlineOutlinedIcon
            style={{ marginRight: '4px', height: '20px', width: '20px' }}
          />{' '}
        </Typography>
      </Stack>
      <Divider sx={{ backgroundColor: '#d3d3d3' }} my={2} />
      {awards?.map((award, i) => {
        return (
          <Stack
            position='relative'
            direction='row'
            key={award?.id}
            alignItems='flex-start'
            justifyContent='space-between'
            sx={{
              '&:hover': {
                '& .del__icon': {
                  display: 'flex',
                },
              },
            }}
          >
            <Stack alignItems='flex-start'>
              <Stack
                direction='row'
                alignItems='center'
                sx={{ color: '#3e6af2' }}
              >
                  <EditableTypography
                  p={1}
                  onBlur={(e) =>
                    updateAwardField({
                      name: 'start_date',
                      text: e.target.innerText,
                      id: award?.id,
                    })
                  }
                  contentEditable
                  placeholder={'2019'}
                  variant='title17Bold'
                  sx={{ fontWeight: '400' }}
                >
                      {award?.start_date}
                  </EditableTypography>
                -{' '}
                  <EditableTypography
                  p={1}
                  onBlur={(e) =>
                    updateAwardField({
                        name: 'end_date',
                      text: e.target.innerText,
                      id: award?.id,
                    })
                  }
                  placeholder={'Present'}
                  contentEditable
                  variant='title17Bold'
                  sx={{ fontWeight: '400' }}
                >
                      {award?.end_date}
                  </EditableTypography>
              </Stack>
              <Stack>
                  <EditableTypography
                  p={1}
                  onBlur={(e) =>
                    updateAwardField({
                      name: 'institute',
                      text: e.target.innerText,
                      id: award?.id,
                    })
                  }
                  placeholder={'Award institute here'}
                  contentEditable
                  variant='title16Bold'
                  sx={{ fontWeight: '700' }}
                >
                      {award?.institute}
                  </EditableTypography>
                  <EditableTypography
                  p={1}
                  onBlur={(e) =>
                    updateAwardField({
                      name: 'award_name',
                      text: e.target.innerText,
                      id: award?.id,
                    })
                  }
                  placeholder={'Award name here'}
                  contentEditable
                  variant='title14Bold'
                  sx={{ fontWeight: '400' }}
                >
                      {award?.award_name}
                  </EditableTypography>
                  <EditableTypography
                  p={1}
                  onBlur={(e) => {
                    updateAwardField({
                      name: 'award_description',
                      text: e.target.innerText,
                      id: award?.id,
                    });
                  }}
                  placeholder={'A brief description of awards here'}
                  contentEditable
                  variant='title14Bold'
                  sx={{ fontWeight: '400' }}
                >
                      {award?.award_description}
                  </EditableTypography>
              </Stack>
            </Stack>

            <Stack
              className='del__icon'
              sx={{
                cursor: 'pointer',
                position: 'absolute',
                right: '0',
                top: '2rem',
                background: '#fff',
                borderRadius: '6px',
                boxShadow: '0px 0px 13px 0px rgba(0,0,0,0.14)',
                height: '40px',
                width: '40px',
                display: 'none',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 10,
              }}
            >
              <DeleteIconCV
                onClick={() => {
                  handleDeleteAward(award?.id);
                }}
              />
            </Stack>
          </Stack>
        );
      })}
    </Stack>
  );
}
