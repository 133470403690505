import React from 'react';

import { IconButton, Stack, Typography } from '@mui/material';

import { PersonalInfoSetting } from '../../../../assets/constants';

export default function PersonalInformationTab() {
  return (
    <Stack
      px={2}
      py={2.2}
      direction='row'
      alignItems='center'
      sx={{
        borderRadius: '11px',
        background: '#fff',
        boxShadow: '0px 0px 14px 0px rgba(0, 0, 0, 0.05)',
      }}
    >
      <IconButton sx={{ height: '43px', width: '43px', background: '#F6F5F4' }}>
        <PersonalInfoSetting />
      </IconButton>
      <Stack pl={2} sx={{ width: 'calc(100% - 50px)' }}>
        <Typography pb={0.7} variant='title14Bold' sx={{ fontWeight: '600' }}>
          Personal Information
        </Typography>
        <Typography
          variant='title12Bold'
          sx={{ fontWeight: '400', color: '#252424' }}
        >
          Update and manage your personal information settings.
        </Typography>
      </Stack>
    </Stack>
  );
}
