import { useEffect, useState } from 'react';

import {
  updateAwardsInfo,
  updateCvInfo,
  updateEducationInfo,
  updateExperienceInfo,
} from '../../components/Seeker/CreateResume/Templates/updateTemplateDataApi';
import { usefetch, headers } from '../../helpers/fetch';
import {
  deleteCvAward,
  deleteCvEducation,
  deleteCvExperience,
  deleteCvLanguage,
  deleteCvSkill,
} from '../../components/Seeker/CreateResume/Templates/deleteTemplateDataApi';
import {
  addCvLanguage,
  addCvSkill,
} from '../../components/Seeker/CreateResume/Templates/addTemplateDataApi';

export const useTemplate = () => {
  const [skill, setSkill] = useState('');
  const [cvInfo, setCVInfo] = useState([]);
  const [awards, setAwards] = useState([]);
  const [language, setLanguage] = useState('');
  const [educations, setEducations] = useState([]);
  const [experiences, setExperiences] = useState([]);
  const [skills, setSkills] = useState([...Array(2)]);
  const [languages, setLanguages] = useState([...Array(2)]);
  const [showQuickActions, setShowQuickAction] = useState(false);

  const [fieldVals, setFieldVals] = useState({
    name: '',
    tagline: '',
    email: '',
    mobile: '',
    address: '',
    link: '',
    summary: '',
    skill: '',
  });

  const handleTextChange = (e, field) => {
    const newValue = e.target.textContent;
    updateCvInfo(field, newValue);
    setFieldVals((prevVals) => ({
      ...prevVals,
      [field]: newValue,
    }));
  };

  const getCv = () => {
    if(!sessionStorage.getItem('cvId')) {
      return;
    }
    usefetch('cv/getCvInfo', 'post', headers(), {
      cvId: sessionStorage.getItem('cvId'),
    }).then(function (resp) {
      setCVInfo(resp?.data?.cvInfo);
    });
  };

  const formatDate = (date) => {
    if (date?.toLowerCase() === 'present') {
      return 'Present';
    }
    const d = new Date(date);
    let year = d.getFullYear();
    return year;
  };

  useEffect(() => {
    getCv();
  }, []);

  useEffect(() => {
    setFieldVals({
      ...fieldVals,
      name: cvInfo?.name ? cvInfo?.name : '',
      tagline: cvInfo?.tagline ? cvInfo?.tagline : '',
      email: cvInfo?.email
        ? cvInfo?.email
          : '',
      mobile: cvInfo?.mobile ? cvInfo?.mobile : '',
      address: cvInfo?.address ? cvInfo?.address : '',
      link: cvInfo?.link ? cvInfo?.link : '',
      summary: cvInfo?.summary
        ? cvInfo?.summary
        : ''
    });
    setEducations(cvInfo?.educations || []);
    setAwards(cvInfo?.awards);
    setLanguages(cvInfo?.languages);
    setSkills(cvInfo?.skills);
    setExperiences(cvInfo?.experiences || []);
  }, [cvInfo]);

  // Education

  const addNewEdu = () => {
    setEducations([{id: Date.now()}, ...educations]);
  };

  const replaceEducationId = (eduId, newId) => {
    const newEducations = [...educations];
    const index = newEducations.findIndex((obj) => obj.id == eduId);
    newEducations[index].id = newId;
    setEducations(newEducations);
  };

  const updateEduField = (params) => {
    const { name, text, id } = params;
    updateEducationInfo(name, text, id).then((resp) => {
      if (resp?.code === '11') {
        const eduId = resp?.data?.eduId;
        replaceEducationId(id, eduId);
      }
    });
  };

  const handleDeleteEducation = (id) => {
    const newEducations = educations?.filter(
      (education) => education?.id !== id,
    );
    deleteCvEducation(id);
    setEducations(newEducations);
  };

  // Awards

  const addNewAward = () => {
    setAwards([{id: Date.now()}, ...awards]);
  };

  const replaceAwardId = (awardId, newId) => {
    const newAwards = [...awards];
    const index = newAwards.findIndex((obj) => obj.id == awardId);
    newAwards[index].id = newId;
    setAwards(newAwards);
  };

  const updateAwardField = (params) => {
    const { name, text, id } = params;
    updateAwardsInfo(name, text, id).then((resp) => {
      if (resp?.code === '11') {
        const awardId = resp?.data?.awardId;
        replaceAwardId(id, awardId);
      }
    });
  };

  const handleDeleteAward = (id) => {
    const newAwards = awards?.filter((award) => award?.id !== id);
    deleteCvAward(id);
    setAwards(newAwards);
  };

  // Language

  const handleDeleteLanguage = (languageId) => {
    setLanguages(languages.filter((obj) => obj.id !== languageId));
    deleteCvLanguage(languageId);
  };

  const handleChangeLangauge = (e) => {
    var { name, value } = e.target;
    var value = (value = value?.trimStart());
    setLanguage(value);
  };

  const addNewLang = async () => {
    const resp = await addCvLanguage(language);
    setLanguages([...languages, { id: Date.now(), language: language }]);
    setLanguage('');
    getCv();
  };

  const keyEnterLanguage = (e) => {
    if (e.key === 'Enter') {
      const { name, value } = e.target;
      if (value === '') {
        return;
      } else {
        addNewLang();
      }
    }
  };

  // Skills

  const handleChangeSkill = (e) => {
    var { name, value } = e.target;
    var value = (value = value?.trimStart());
    setSkill(value);
  };

  const addNewSkill = async () => {
    const resp = await addCvSkill(skill);
    setSkills([...skills, { id: Date.now(), skill: skill }]);
    setSkill('');
    getCv();
  };

  const keyEnterSkill = (e) => {
    if (e.key === 'Enter') {
      const { name, value } = e.target;
      if (value === '') {
        return;
      } else {
        addNewSkill();
      }
    }
  };

  const handleDeleteSkill = (skillId) => {
    setSkills(skills.filter((obj) => obj.id !== skillId));
    deleteCvSkill(skillId);
  };

  // Employment

  const addNewExp = () => {
    setExperiences([{id: Date.now()}, ...experiences]);
  };

  const handleDeleteExp = (id) => {
    const newExperiences = experiences?.filter(
      (experience) => experience?.id !== id,
    );
    deleteCvExperience(id);
    setExperiences(newExperiences);
  };

  const replaceExpId = (expId, newId) => {
    const newExperiences = [...experiences];
    const index = newExperiences.findIndex((obj) => obj.id == expId);
    newExperiences[index].id = newId;
    setExperiences(newExperiences);
  };

  const updateFieldExp = (params) => {
    const { name, text, id } = params;
    updateExperienceInfo(name, text, id).then((resp) => {
      if (resp?.code === '11') {
        const expId = resp?.data?.expId;
        replaceExpId(id, expId);
      }
    });
  };

  return {
    cvInfo,
    fieldVals,
    handleTextChange,
    addNewEdu,
    educations,
    updateEduField,
    formatDate,
    handleDeleteEducation,
    addNewAward,
    awards,
    updateAwardField,
    handleDeleteAward,
    languages,
    handleDeleteLanguage,
    keyEnterLanguage,
    handleChangeLangauge,
    language,
    setShowQuickAction,
    showQuickActions,
    handleChangeSkill,
    keyEnterSkill,
    handleDeleteSkill,
    skill,
    skills,
    addNewExp,
    handleDeleteExp,
    updateFieldExp,
    experiences,
  };
};
