import {useState} from "react";
import {defaultHeaders, usefetch} from "../../helpers/fetch";
import {isEmpty} from "../../helpers/common";

export const useGetTopAnswers = () => {
    const [error, setError] = useState(null);
    const [isGettingTopAnswers, setIsGettingTopAnswers] = useState(null);
    const [response, setResponse] = useState(null);
    const [topQuestions, setTopQuestions] = useState([]);
    const [question, setQuestion] = useState(null);
    const [questionsCount, setQuestionsCount] = useState(null);
    const [questionLimit, setQuestionLimit] = useState(null);


    const getTopAnswers = async (data) => {
        setIsGettingTopAnswers(true);
        const endpoint = 'interview/getTopAnswers';
        const response = await usefetch(endpoint, 'post', defaultHeaders(), data);

        if (!response.ok) {
            setError(response.error);
        }

        if (response.ok) {
            setResponse((prevData) => [...prevData, response]);
        }

        setIsGettingTopAnswers(false);
        // setTopQuestions((prev) => [...prev, response?.data?.questions]);
        if(isEmpty(data?.start)) {
            setTopQuestions(response?.data?.questions);
        } else {
            setTopQuestions([...topQuestions, ...response?.data?.questions]);
        }

        // setTopQuestions(response?.data?.questions);
        // setQuestion(response?.data?.question);
        setQuestionsCount(response?.data?.questionsCount);
        setQuestionLimit(response?.data?.questionLimit);
        return response;
    };

    return { getTopAnswers, response,  topQuestions,  isGettingTopAnswers, questionLimit,  questionsCount, error };
};