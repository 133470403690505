import React, { useState } from 'react';

import { Stack, Typography } from '@mui/material';

import SwitchMain from '../../../../common/Switch';

export default function JobMatches() {
  const [checked, setChecked] = useState(true);
  const handleCheck = () => {
    setChecked(!checked);
  };
  return (
    <Stack
      mb={'16px'}
      px={2}
      py={2.2}
      direction='row'
      alignItems='center'
      justifyContent='space-between'
      sx={{
        borderRadius: '11px',
        background: '#fff',
        boxShadow: '0px 0px 14px 0px rgba(0, 0, 0, 0.05)',
      }}
    >
      <Stack pl={2} sx={{ width: 'calc(100% - 50px)' }}>
        <Typography pb={0.7} variant='title14Bold' sx={{ fontWeight: '600' }}>
          See Your Job Matches!
        </Typography>
        <Typography
          variant='title12Bold'
          sx={{ fontWeight: '400', color: '#252424' }}
        >
          Activate this feature to see jobs that match your profile instantly!
        </Typography>
      </Stack>
      <SwitchMain onChange={handleCheck} checked={checked === true} />
    </Stack>
  );
}
